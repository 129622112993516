<template>
  <v-container class="mkt-content-container mt-5">
    <px-marketplace-view-header title="Avalie sua compra"/>
    <px-avaliacao-compra-realizada v-if="!loading && avaliacao && isRespondido"/>
    <div class="mt-12" v-if="loading">
      <px-circular-loading/>
    </div>
    <div class="mt-12" v-if="!loading && avaliacao && !isRespondido">
      <v-card outlined class="rounded-card border-color-primary">
        <v-card-text class="text--primary">
          <div class="pa-6">
            <span class="title font-weight-bold text--primary--color">
              {{ avaliacao.compra.fornecedor.pessoa.nome }}
              <px-open-pagina-fornecedor v-if="isLogged"
                                         :id="avaliacao.compra.fornecedor.id"/>
            </span>
          </div>
          <template v-for="(item, index) in avaliacoesItens">
            <px-avaliacao-compra-item class="mt-10 mb-12"
                                      :key="item.oferta.id"
                                      :item-avaliacao="item"/>
            <v-divider :key="`div-${item.oferta.id}`"
                       class="mt-12"
                       v-if="showDivider(index)"/>
          </template>
          <v-row class="ml-3" v-if="showError">
            <v-col cols="12" class="error--text">
              É necessário informar a avaliação geral para todos os produtos
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="mt-5 text-right">
        <v-btn large
               color="primary"
               class="rounded-card-small normal-btn-text px-12"
               :loading="loadingFinalizar"
               @click="onClickFinalizar">
          Finalizar
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { flatten } from 'lodash';
import { StatusAvalicaoCompra } from 'px-business-components';
import AvaliacaoCompraItem from './AvaliacaoCompraItem.vue';
import AvaliacaoService from './avaliacao-service';
import AvaliacaoCompraRealizada from './AvaliacaoCompraRealizada.vue';

export default {
  data() {
    return {
      hash: this.$route.params.hash,
      avaliacao: null,
      loading: false,
      loadingFinalizar: false,
      showError: false,
      avaliacoesItens: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    showDivider() {
      return index => index !== (this.avaliacoesItens.length - 1);
    },
    isRespondido() {
      return this.avaliacao.status === StatusAvalicaoCompra.keys.RESPONDIDO.key;
    },
  },
  methods: {
    getAvaliacao() {
      this.loading = false;
      AvaliacaoService.getAvaliacao(this.hash)
        .then(({ data }) => {
          this.avaliacao = data;
          this.buildItens();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildItens() {
      const ofertas = flatten(this.avaliacao.compra.pacotes
        .map(pacote => pacote.ofertas));
      this.avaliacoesItens = ofertas.map(oferta => ({
        oferta,
        avaliacao: null,
        comentario: null,
      }));
    },
    isValid() {
      return this.avaliacoesItens.every(avaliacao => avaliacao.avaliacao);
    },
    buildPayload() {
      const avaliacoes = this.avaliacoesItens.map(avaliacao => ({
        ofertaCompra: {
          id: avaliacao.oferta.id,
        },
        avaliacao: {
          pontuacao: avaliacao.avaliacao,
          comentario: avaliacao.comentario,
        },
      }));
      return {
        avaliacoesOfertas: avaliacoes,
      };
    },
    onClickFinalizar() {
      if (!this.isValid()) {
        this.showError = true;
        return;
      }
      this.loadingFinalizar = true;
      AvaliacaoService.createAvaliacao(this.hash, this.buildPayload())
        .then(() => {
          this.$toast('Sua avaliação foi enviada', { color: 'success' });
          if (this.isLogged) {
            this.$router.push({
              name: 'main.compra.detalhes',
              params: {
                id: this.avaliacao.compra.id,
              },
            });
            return;
          }
          this.$router.push({ name: 'public.home' });
        })
        .finally(() => {
          this.loadingFinalizar = false;
        });
    },
  },
  created() {
    this.getAvaliacao();
  },
  components: {
    pxAvaliacaoCompraItem: AvaliacaoCompraItem,
    pxAvaliacaoCompraRealizada: AvaliacaoCompraRealizada,
  },
};
</script>
